import clsx from 'clsx';
import { usePortalNode } from 'common/hooks/usePortalNode';
import { useToggler } from 'common/hooks/useToggler';
import { ReactComponent as IconClose } from 'images/close.svg';
import { ReactComponent as IconHamburger } from 'images/hamburger.svg';
import React, { FC, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import styles from './BurgerMenuWrapper.module.less';

interface Props {
  children: (params: { open: VoidFunction; close: VoidFunction }) => ReactNode;
  className?: string;
}

export const BurgerMenuWrapper: FC<Props> = (props) => {
  const { className, children } = props;
  const { value: visible, toggle, setFalse: hideMenu, setTrue: openMenu } = useToggler(false);
  const { pathname } = useLocation();

  const [portalNode] = usePortalNode({
    nodeParams: {
      className: styles.overlay,
    },
  });

  const classes = clsx({
    [styles.content]: true,
    [styles.visible]: visible,
  });
  const Content = <div className={classes}>{children({ open: openMenu, close: hideMenu })}</div>;
  const Icon = visible ? IconClose : IconHamburger;

  // Hide menu after location change.
  useEffect(() => {
    if (pathname !== undefined) {
      hideMenu();
    }
  }, [hideMenu, pathname]);

  return (
    <>
      <button data-testid="BurgerMenu" className={clsx(styles.wrapper, className)} onClick={toggle}>
        <Icon className={styles.icon} />
      </button>
      {createPortal(Content, portalNode)}
    </>
  );
};
