import { DeviceTypes } from 'common/enums/deviceTypes';
import styles from 'styles/utils/_variables.module.less';

export const getDeviceTypeByWindowSize = () => {
  if (typeof window !== 'undefined') {
    const windowWidth = window.innerWidth;

    return windowWidth >= parseInt(styles.desktopWidth, 10)
      ? DeviceTypes.DESKTOP
      : windowWidth >= parseInt(styles.tabletWidth, 10)
        ? DeviceTypes.TABLET
        : DeviceTypes.MOBILE;
  }

  return DeviceTypes.DESKTOP;
};
