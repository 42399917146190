import { useEffect, useState } from 'react';

interface NodeParams<K extends keyof HTMLElementTagNameMap> {
  tagName?: K;
  id?: string;
  className?: string;
}

interface UsePortalNodeParams<K extends keyof HTMLElementTagNameMap> {
  nodeParams?: NodeParams<K>;
}

/**
 * Creates node for portal usage and appends it to the body's end.
 * Useful in cases with fixed overlay without z-index problems.
 */
export const usePortalNode = <K extends keyof HTMLElementTagNameMap = 'div'>(
  params: UsePortalNodeParams<K> = {},
): [HTMLElementTagNameMap[K]] => {
  const [node] = useState(() => {
    const { tagName = 'div', className = '', id = Math.random() } = params.nodeParams || {};
    const element = document.createElement(tagName);

    element.id = String(id);
    element.className = className;

    return element as HTMLElementTagNameMap[K];
  });

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    document.body.appendChild(node);

    return () => {
      if (typeof document === 'undefined') {
        return;
      }

      document.body.removeChild(node);
    };
  }, [node]);

  return [node];
};
