import { Col, Row } from 'antd';
import routes from 'common/routes';
import { HeaderLogo } from 'components/Header/HeaderLogo';
import { LanguageChanger } from 'components/LanguageChanger';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './PublicHeader.module.less';

export const PublicHeader: FC = () => {
  const isSignUpPage = window.location.href.includes(routes.profileSignUp);
  const { t } = useTranslation();

  return (
    <Row className={styles.container}>
      <Col xl={12} sm={12} xs={14} className={styles.logoCnt}>
        <HeaderLogo />
      </Col>
      <Col xl={12} sm={12} xs={10} className={styles.menuCnt}>
        <LanguageChanger />
        <div className={styles.signBtns}>
          {isSignUpPage ? (
            <Trans t={t} i18nKey={'buttons:signInLink'}>
              <span>Already a partner?</span>
              <Link to={routes.profileSignIn}>Sign in</Link>
            </Trans>
          ) : (
            <Trans t={t} i18nKey={'buttons:signUpLink'}>
              <span>Not a partner? </span>
              <Link to={routes.profileSignUp}>Sign up now</Link>
            </Trans>
          )}
        </div>
      </Col>
    </Row>
  );
};
