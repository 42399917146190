import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './HeaderMenuSeparator.module.less';

interface Props {
  className?: string;
}

export const HeaderMenuSeparator: FC<Props> = (props) => {
  const { className } = props;

  return <div className={clsx(styles.wrapper, className)} />;
};
