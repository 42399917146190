import cn from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import styles from './ContentWrapper.module.less';

interface ContentWrapperProps {
  containerClassName?: string;
}
const ContentWrapper: FC<PropsWithChildren<ContentWrapperProps>> = ({ children, containerClassName }) => {
  return <div className={cn(containerClassName, styles.container)}>{children}</div>;
};

export default ContentWrapper;
